import React, { useEffect, useState } from "react";

// React Navigation
import { useNavigate } from "react-router-dom";

// Axios
import Axios from "../../api/axios";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// CSS styling
import "../../styles/home.css";

// React Slideshow Image
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Icons, Background, Card, and Logo
import CCSlogo from "../../images/companyLogo/CCSblack-Logo.png";
import SideBanner1 from "../../images/background/ISA_WEB_BANNER_1.jpg";
import SideBanner2 from "../../images/background/ISA_WEB_BANNER_2.jpg";
import CardProgram1 from "../../images/programCards/program-card-1.png";
import CardProgram2 from "../../images/programCards/program-card-2.png";
import CardProgram3 from "../../images/programCards/program-card-3.jpg";
import CardProgram4 from "../../images/programCards/program-card-4.png";
import PlayButton from "../../images/icons/playButton.png";
import landing_page_infographic_banner from "../../images/diplomaESG/landing_page_infographic_banner.png";

// Components
import PaginatedItems from "../../components/Pagination";
import ContainerText from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// banner
import banner_utama from "../../images/eventCards/banner_utama.jpeg";

const HomeBanner =
  "https://ik.imagekit.io/4n54l5tm8yf/ISA/HomeBanner_E_t-ik9NY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1650859450189";

const Home = () => {
  const navigate = useNavigate();

  const [bannerImages] = useState([
    { img_url: banner_utama, learn_more_url: "/Diploma-PM-for-ESG" },
    { img_url: HomeBanner, learn_more_url: "/Expertise" },
  ]);

  const [news, setNews] = useState([]);
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    const response = await Axios.get("/client").catch((err) => {
      console.log("Err: ", err);
    });
    setClients(response.data);
  };

  const fetchNews = async () => {
    const response = await Axios.get("/news/updatedLimit").catch((err) => {
      console.log("Err: ", err);
    });
    setNews(response.data);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
    fetchClients();
    fetchNews();
  }, []);

  const buttonStyle = {
    position: "absolute",
    zIndex: "1",
    cursor: "pointer",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    color: "#000000",
    fontSize: "80px",
    padding: "20px",
    borderRadius: "50%",
  };

  const properties = {
    prevArrow: (
      <button
        className="arrow arrow-left"
        style={{ ...buttonStyle, left: 30 }}
        hidden
      >
        <span>&lt;</span>
      </button>
    ),
    nextArrow: (
      <button
        className="arrow arrow-right"
        style={{ ...buttonStyle, right: 30 }}
        hidden
      >
        <span>&gt;</span>
      </button>
    ),
  };

  const mediaQueryMobile = window.matchMedia("(max-width: 640px)");
  const mediaQueryTab = window.matchMedia("(max-width: 910px)");

  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isTabSize, setIsTabSize] = useState(false);

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsMobileSize(mediaQuery.matches);
    } else {
      setIsTabSize(mediaQuery.matches);
    }
  };

  useEffect(() => {
    if (mediaQueryMobile) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    if (mediaQueryTab) {
      handleDetectWindowSize(mediaQueryTab, "tab");
    }
  }, [mediaQueryTab]);

  return (
    <div id="home">
      {/* Home Banner */}
      <Header />
      <div id="homeBanner">
        <Slide {...properties}>
          {bannerImages.map((slideImage, idx) => {
            const { img_url, learn_more_url } = slideImage;
            return (
              <div
                key={idx}
                onClick={() => (idx === 0 ? navigate(learn_more_url) : null)}
              >
                <div
                  style={
                    isMobileSize
                      ? {
                          marginTop: "6.1vh",
                          height: "225px",
                          cursor: idx === 0 ? "pointer" : null,
                        }
                      : isTabSize
                      ? {
                          marginTop: "8vh",
                          height: "500px",
                          cursor: idx === 0 ? "pointer" : null,
                        }
                      : {
                          marginTop: idx === 0 ? "17vh" : "-23vh",
                          cursor: idx === 0 ? "pointer" : null,
                        }
                  }
                >
                  <img
                    src={img_url}
                    alt="homeBanner"
                    style={{
                      filter: idx !== 0 ? "brightness(70%)" : null,
                    }}
                    // style={
                    //   isMobileSize
                    //     ? {
                    //         height: "225px",
                    //       }
                    //     : isTabSize
                    //     ? {
                    //         height: "500px",
                    //       }
                    //     : {}
                    // }
                  />
                </div>
                {idx !== 0 ? (
                  <div className="homeBannerContainer">
                    <ContainerText
                      props={
                        <div className="textBanner">
                          <div>
                            <p className="textBannerMain">
                              Inspire Excellence and Make a Difference
                            </p>
                          </div>
                          <div data-aos="fade-left" data-aos-once>
                            <p className="textBannerSub">
                              Indonesia's leading in Sustainability Education
                              and Consultancy
                            </p>
                          </div>
                          <div data-aos="fade-left" data-aos-once>
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => navigate(learn_more_url)}
                            >
                              Learn more
                            </button>
                          </div>
                        </div>
                      }
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
        </Slide>
      </div>
      {/* Infographic about ESG and Sustainability */}
      <div style={{ marginTop: "7em" }}>
        <ContainerText
          props={
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  "/Diploma-PM-for-ESG/download-file-form/  infographic-esg-sustainability"
                )
              }
            >
              <img
                src={landing_page_infographic_banner}
                alt="infographics banner"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
          }
        />
      </div>
      {/* Vision and Goal */}
      <div id="visionAndGoal">
        <ContainerText
          props={
            <div>
              <div>
                <p className="visionAndGoalText">
                  <span className="visionAndGoalTextMain">Our Vision</span> is
                  to Inspire, Educate, and Collaborate with Individuals and
                  Organizations in Achieving Sustainability
                </p>
              </div>
              <div>
                <p className="visionAndGoalText">
                  <span className="visionAndGoalTextMain">Our Goal</span> is to
                  be a credible Think-Tank for Leadership, Education and
                  Partnership in Sustainability for the World
                </p>
              </div>
            </div>
          }
        />
      </div>
      {/* Side Banner */}
      <div id="sideBanner">
        <div className="sideBannerLeft">
          <img src={SideBanner1} alt="" />
          <ContainerText
            marginLeft={0}
            props={
              <div className="sideBannerLeftContainer">
                <div className="sideBannerLeftText">
                  <p className="sideBannerTextMain">
                    Creating Meaningful Change for Sustainable Future
                  </p>
                  <p className="sideBannerTextSub">
                    Our planet’s condition is rapidly changing due to climate
                    change, and we know that it becomes more drastic with each
                    passing moment. For the future of our world, we realize that
                    major changes need to be made to keep our planet livable,
                    but many of those changes can only be brought on by those
                    with the capacity to do so. Due to this, businesses now play
                    a vital role in our current society.
                  </p>
                  <a className="sideBannerTextLink d-none" href="#">
                    Read full mission statement{" "}
                    <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            }
          />
        </div>
        <div className="sideBannerRight">
          <img src={SideBanner2} alt="" />
          <ContainerText
            marginRight={0}
            props={
              <div className="sideBannerRightContainer">
                <div className="sideBannerRightText">
                  <p className="sideBannerTextMain">
                    We are committed to your success
                  </p>
                  <p className="sideBannerTextSub">
                    At ISA, giving our best is not just an option, it is
                    something that we know we must do. We don’t want to simply
                    give businesses their basic needs in sustainability, but we
                    also want to go beyond it. Ensuring the satisfaction of
                    those that collaborate with us is a task that we constantly
                    keep in mind.
                  </p>
                  <a className="sideBannerTextLink d-none" href="#">
                    Learn more <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            }
          />
        </div>
      </div>
      {/* Programs */}
      <div id="programs">
        <div className="webTitle">
          <p>Let Us Help you to Achieve Sustainability</p>
        </div>
        <ContainerText
          props={
            <div className="programsCard">
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/ESGroadMap`)}
              >
                <img src={CardProgram1} alt="" />
                <p>ESG Roadmap, Policies, Target and Metrics</p>
              </div>
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/ImpactAnalysis`)}
              >
                <img src={CardProgram2} alt="" />
                <p>Impact Analysis and Reporting</p>
              </div>
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/SustainabilityReport`)}
              >
                <img src={CardProgram3} alt="" />
                <p>Sustainability Report</p>
              </div>
              <div
                className="programsEachCard"
                onClick={() => navigate(`/Expertise/VocationalStudies`)}
              >
                <img src={CardProgram4} alt="" />
                <p>
                  Vocational Studies
                  <br />
                  <span className="programsEachCardSmallText">
                    Collaboration with Australia-based Polytechnic
                  </span>
                </p>
              </div>
            </div>
          }
        />
      </div>
      {/* Events */}
      <div id="events" style={{ display: "none" }}>
        <div className="webTitle">
          <p>Join Our Events</p>
        </div>
        <div className="eventsPagination">
          <ContainerText props={<PaginatedItems itemsPerPage={3} />} />
        </div>
      </div>
      {/* Small Banner */}
      <div id="mentorship" style={{ display: "none" }}>
        <ContainerText
          props={
            <div className="mentorshipContainer">
              <div className="mentorshipCollaboration">
                <p>In collaboration with:</p>
                <img src={CCSlogo} alt="CCS" />
              </div>
              <div className="mentorshipMain">
                <div className="mentorshipMainText">
                  <p>Get FREE 1-On-1 Mentorship Session</p>
                </div>
                <div className="mentorshipMainButton">
                  <button type="button" className="btn">
                    Join now
                  </button>
                </div>
              </div>
              <div className="mentorshipSubtext">
                <p>Let’s solve your challenges together</p>
              </div>
            </div>
          }
        />
      </div>
      {/* News Updates */}
      {news.length > 0 ? (
        <div id="news">
          <div className="webTitle">
            <p>Read the Latest Updates</p>
          </div>
          <ContainerText
            props={
              <div className="newsCards">
                {news.map((element) => {
                  return element.status === "Published" ? (
                    <div
                      className="newsEachCard"
                      key={element.id}
                      onClick={() => navigate(`/Blog/${element.id}`)}
                    >
                      <img src={element.picture} alt="newsCard" />
                      <div className="newsEachCardText">
                        <p>
                          {element.title} <br />
                          <div className="newsEachCardTextSub">
                            <span>{element.description}</span>
                            <div className="newsArrow">
                              <i className="fas fa-arrow-right newsSmallArrow"></i>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            }
          />
          <div className="buttonViewAll">
            <a
              style={{ border: "1px solid black" }}
              href="#blog"
              onClick={() => navigate(`/Blog`)}
            >
              View all
            </a>
          </div>
        </div>
      ) : null}
      {/* Clients */}
      <div id="clients">
        <div className="webTitle">
          <p>Our Clients</p>
        </div>
        <ContainerText
          props={
            <div className="clientsLogo">
              {clients.map((client) => (
                <div className="clientsLogoContainer" key={client.id}>
                  <img src={client.clientImage} alt={client.clientName} />
                </div>
              ))}
            </div>
          }
        />
        <div className="buttonViewAll">
          <a
            style={{ border: "1px solid black" }}
            href="#clientsPage"
            onClick={() => navigate(`/Clients`)}
          >
            View more
          </a>
        </div>
      </div>
      {/* Videos */}
      <div id="videos">
        <ContainerText
          props={
            <div className="videosContainer">
              <div className="webTitle">
                <p>Watch our ISA Learning Videos</p>
              </div>
              <div className="videosSubtitle">
                <p>
                  Gain insights about Sustainability and what we can do for
                  future generations
                </p>
              </div>
              <div className="videosCard">
                <div className="videosEachCard">
                  <iframe
                    className="videosResponsive"
                    src="https://www.youtube.com/embed/5ZQqKrnX77Y"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="videosEachCard">
                  <iframe
                    className="videosResponsive"
                    src="https://www.youtube.com/embed/guPAuU5oaWQ"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="videosEachCard">
                  <iframe
                    className="videosResponsive"
                    src="https://www.youtube.com/embed/11N-U57i6Dg"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="videosEachCard">
                  <iframe
                    className="videosResponsive"
                    src="https://www.youtube.com/embed/ghB8QJVw2Ag"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="videosExploreMore">
                <a
                  style={{ border: "1px solid black" }}
                  href="https://www.youtube.com/channel/UCWsQCHf9PodPl-_V_GaYorg/featured"
                  target="_blank"
                  rel="noreferrer"
                >
                  Explore more <img src={PlayButton} alt="" />
                </a>
              </div>
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
